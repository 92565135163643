<template>
  <div id="c1-gigya-container" />
</template>

<script setup>
const config = useRuntimeConfig();
const { activateTheme } = useThemeService();
const { hideLoader } = useLoaderService();
const { getInvitePreFill, getInviteDetails } = useInvitationService();
const { gigyaLoadedPromise } = useGigyaManagerService();

const themes = {
  '-primary': config.public.themeTypes.primary.code,
  '-secondary': config.public.themeTypes.secondary.code
};

const route = useRoute();

definePageMeta({
  layout: 'user-reg-login-layout',
  middleware: ['common-loader', 'page-redirection']
});

onBeforeMount(() => {
  activateTheme(
    themes[route.params.segment] ?? config.public.themeTypes.adult.code
  );
  hideLoader();
});

onMounted(async () => {
  if (getInvitePreFill()) {
    window.addEventListener('GIGYA_AFTER_SCREEN_LOAD', handleAfterScreenLoad);
  }
  let screenset = '';
  if (route.query.u) {
    sessionStorage.setItem('desktop-login-token', route.query.u);
    localStorage.setItem('desktop-login-token', route.query.u);
    screenset = 'CAMBRIDGEONE-RegistrationLogin-Desktop';
  }

  if (window['gigya']) {
    initGigyaScreen(
      screenset,
      config.public.gigyaScreens[route.path],
      'c1-gigya-container'
    );
  } else {
    window.addEventListener('gigyaLoaded', () =>
      initGigyaScreen(
        screenset,
        config.public.gigyaScreens[route.path],
        'c1-gigya-container'
      )
    );
  }

  // Needs to await as need gigya.
  !window.gigya && (await gigyaLoadedPromise());

  // Redirect to desktop-login if user is already logged in
  // and has come to login page with u query param
  if (route.query.u) {
    const userLoggedIn = await useGigyaSession().getGigyaUserData();
    if (userLoggedIn) {
      navigateTo({ path: 'desktop-login', query: { u: route.query.u } });
    }
  }
});

function handleAfterScreenLoad(data) {
  const inviteDetails = getInviteDetails();
  if (data.detail.event.currentScreen == 'nemo-login') {
    //Set data and disable fields and links to move to other screen
    setInviteData(inviteDetails);
    disableFieldsAndLinks();
    setInvitationTooltipVisibility(true);
  }
}

function setInviteData(inviteDetails) {
  const emailInputPreset = document.querySelector(
    '#c1-gigya-container .inputEmail input'
  );
  emailInputPreset.value = inviteDetails.email;
  emailInputPreset.setAttribute('disabled', 'disabled');
}

function disableFieldsAndLinks() {
  const registerLink = document.querySelector('#c1-gigya-container .nemo-link');
  registerLink.classList.add('d-none');
}

function setInvitationTooltipVisibility(visible) {
  const invitationTooltip = document.querySelector(
    '#c1-gigya-container .invite-tooltip'
  );
  invitationTooltip.classList.toggle('d-none', !visible);
}
</script>
